export const LoaderSvg = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.75 7.75L5.6 5.6M12 6V3V6ZM16.25 7.75L18.4 5.6L16.25 7.75ZM18 12H21H18ZM16.25 16.25L18.4 18.4L16.25 16.25ZM12 18V21V18ZM7.75 16.25L5.6 18.4L7.75 16.25ZM6 12H3H6Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
